<template>
<div class="content-template">
  <div
      class="card animate__animated animate__fadeIn duration"
      id="card1"
    >
      <div class="card_box_one">
        <div class="card_box_title2">
          <p class="font-blod">龙虎榜资金</p>
          <p>日内追踪</p>
          <p>{{dataTime}}</p>
        </div>
        <div class="width-100 height-100">
          <video
            src="video/video2.mp4"
            muted
            width="100%"
            height="100%"
            ref="video1"
          ></video>
        </div>
        <div class="logo-box">
          <img src="../assets/img/logo2.png" alt="" />
        </div>
      </div>
    </div>
  <div class="container">
    <div class="audio-box">
      <audio src="audio/RaisingMeHigher.mp3" ref="bgAudio"></audio>
      <audio :src="audio1Src" ref='audio1'></audio>
      <audio :src="audio2Src" ref='audio2'></audio>
      <audio :src="audio3Src" ref='audio3'></audio>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card2"
    >
      <div>
        <div class="card_header">
          <span class="font-blod">龙虎榜</span>
          <span class="font-18">-机构净流入个股</span>
          <span style="margin-top:4px">{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="flex flex-between margin-t-10">
            <p style="color:#FFF06B">龙虎榜揭秘  机构抢筹这{{leng1}}股！</p>
            <p>(万元)</p>
          </div>
          <div id="chart1" style="width: 100%; height: 460px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div
      class="card animate__animated animate__fadeIn duration none"
      id="card3"
    >
     <div>
        <div class="card_header">
          <span class="font-blod">龙虎榜</span>
          <span class="font-18">-营业部净流入个股</span>
          <span style="margin-top:4px">{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="flex flex-between margin-t-10">
            <p style="color:#FFF06B">营业部净买入<span>{{totalPrice2}}</span>万元，最青睐{{leng2}}股！</p>
            <p>(万元)</p>
          </div>
          <div id="chart2" style="width: 100%; height: 460px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
    <div class="card animate__animated animate__fadeIn duration none" id="card4">
     <div>
        <div class="card_header">
          <span class="font-blod">龙虎榜</span>
          <span class="font-18">-活跃营业部</span>
          <span style="margin-top:4px">{{dataTime}}</span>
        </div>
        <div style="padding: 0px 18px">
          <div class="card4_box card4_box_title">
            <p>名称</p>
            <p>参与股票</p>
            <p>净买入(万)</p>
          </div>
          <div class="card4_content">
            <div class="card4_box_move">
              <div class="card4_box" v-for="(item,index) in businessList" :key="index">
                <p>{{item.name}}</p>
                <p>{{item.stock}}</p>
                <p :class="item.mount*1 > 0 ? 'isred' : 'islu' ">{{item.mount}}</p>
              </div>
            </div>

          </div>
          <div id="chart2" style="width: 100%; height: 460px"></div>
        </div>
      </div>
      <div class="logo-box logo-box2">
        <img src="../assets/img/logo2.png" alt="" />
      </div>
    </div>
  </div>
  <div class="card animate__animated animate__fadeIn duration none" id="card5">
    <div class="width-100 height-100">
      <video
        class="end_video"
        src="video/end.mp4"
        muted
        width="100%"
        height="100%"
        ref="endVideo"
      ></video>
    </div>
  </div>
</div>
</template>
<script>
import $ from "jquery";
import { GetParams } from '../utils/common'
import ApiServe from '@/api/index'
export default {
  name: "Template4",
  data() {
    return {
      dataTime:'',
      chart_data_1: [],
      chart_data_2: [],
      leng2:null,
      leng1:null,
      totalPrice2:null,
      audioSrc:'',
      xdata1:[],
      ydata1:[],
      xdata2:[],
      ydata2:[],
      audio1Src:"",
      audio2Src:"",
      audio3Src:'',
      device_id: null,
      model_id: null,
      record_time: null,
      real: 1, //0测试, 1正式
      businessList:[]
    };
  },
  created() {
    this.device_id = GetParams().device_id || null;
    this.model_id = GetParams().model_id || null;
    this.record_time = GetParams().record_time || 46;
    this.getData();
    this.getAudio();
    //this.getHBDepList()
  },
  computed: {
    allReady() {
      const { chart_data_1, audio1Src } = this;
      return {
        chart_data_1,
        audio1Src,
      };
    },
  },
  watch: {
    allReady(val) {
      if (val.chart_data_1.length > 0 && val.audio1Src) {
        if (this.device_id && this.real) {
          setTimeout(() => {
            ApiServe.recordStart({
                model_id: this.model_id,
                device_id: this.device_id,
                record_time: this.record_time,
                real: this.real,
              }).then(({code})=>{
              if(code == 200){
                 this.load();
              }
            }) 
          }, 5000);
        } else {
         this.load();
           
        }
      }
    },
  },
  methods: {
    getData() {
      ApiServe.getData({ model_id: 4 }).then(({ data, code }) => {
          if (code == 200) {
            this.dataTime = data.extra_param_1[0]
            this.chart_data_1 = data.chart_data_1
            this.chart_data_2 = data.chart_data_2
            this.leng1 =  data.chart_data_1[0].data.x.length
            this.leng2 =  data.chart_data_2[0].data.x.length
            this.totalPrice2 = data.extra_param_3[0]
            this.xdata1 = data.chart_data_1[0].data.x || []
            this.ydata1 = data.chart_data_1[0].data.y || []
            this.xdata2 = data.chart_data_2[0].data.x || []
            this.ydata2 = data.chart_data_2[0].data.y || []
            this.businessList = data.chart_data_3[0].data.org.map((item,index)=>{
              return {
                name:item,
                stock:data.chart_data_3[0].data.oo[index],
                mount:data.chart_data_3[0].data.zz[index]
              }
            })    
          }
        }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        })
    },
    getHBDepList(){
      ApiServe.getHbDepData()
    },
    // 获取旁白
    getAudio() {
      ApiServe.getVideoData({ model_id: 4 }).then(({ data, code,dd }) => {
          if (code == 200) {
            // this.audioSrc = dd;
          this.audio1Src = data[0].audio_link
          this.audio2Src = data[1].audio_link
          this.audio3Src = data[2].audio_link
          }
        }).catch((err)=>{
          if(this.device_id){
            ApiServe.releasedSevice({ device_id: this.device_id })
          }
        });
    },
    
    // 龙虎榜-营业部净买入个股
    loadChart1() {
      // 基于准备好的dom，初始化this.$echarts实例
      var myChart = this.$echarts.init(document.getElementById("chart1"));
      // 指定图表的配置项和数据
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "55px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.xdata1,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata1,
            barWidth: 24,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 14,
                  },
                  formatter: function (value) {
                    return Number(value.value).toFixed(2);
                  },
                },
                color: "#0683C2",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },

    // 龙虎榜-机构净买入个股
     loadChart2() {
      var myChart = this.$echarts.init(document.getElementById("chart2"));
      var option = {
        grid: {
          left: "60px",
          top: "20px",
          bottom: "26px",
          right: "55px",
        },
        animationDuration: 4000,
        xAxis: [
          {
            type: "value",
            show:false,
          },
        ],
        yAxis: [
          {
            type: "category",
            inverse: true,
            data: this.xdata2,
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel:{
              color:'#AFF7FF'
            }
          },
        ],
        series: [
          {
            type: "bar",
            data: this.ydata2,
            barWidth: 24,
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "right", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#AFF7FF",
                    fontSize: 14,
                  },
                  formatter: function (value) {
                    return Number(value.value).toFixed(2);
                  },
                },
                color: "#0683C2",
              },
            },
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    // 龙虎榜 营业部向上移动
    bussisDepartMove(){
      const moveNum = $('.card4_box_move').height() - $('.card4_content').height()
      $('.card4_box_move').animate({bottom:-moveNum},6000)
    },
    load() {
      this.$refs.bgAudio.volume = 0.4; //背景音乐音量调节（1为最大）
      this.$refs.bgAudio.play(); //背景音乐播放
      this.$refs.video1.play(); //首页的视频播放
      setTimeout( ()=> {
        $("#card1").addClass("animate__fadeOut");
      }, 2000);
      setTimeout(() => {
        $("#card1").addClass("none");
        $("#card2").removeClass("none");
        this.$refs.audio1.play()
        if(this.chart_data_1[0].data.x.length>10){
          let arrList1 = this.chart_data_1[0].data.x
          let num1 = arrList1.length -10
          let n = 0
          this.xdata1 = arrList1.slice(arrList1.length-9,arrList1.length)
          this.ydata1 = this.chart_data_1[0].data.y.slice(arrList1.length-9,arrList1.length)
          let inter1 = setInterval(()=>{
              n++;
              if(n<=num1){
                this.xdata1.pop()
                this.xdata1.unshift(this.chart_data_1[0].data.x[num1-n])
                this.ydata1.pop()
                this.ydata1.unshift(this.chart_data_1[0].data.y[num1-n])
                this.loadChart1()
              }
            },500)
          setTimeout(()=>{
            clearInterval(inter1)
          },(num1/2)*1000)
        }
        this.loadChart1();
      }, 3000);
      setTimeout( ()=> {
        $("#card2").addClass("animate__fadeOut");
      }, 14000);
      setTimeout(() => {
        $("#card2").addClass("none");
        $("#card3").removeClass("none");
        this.$refs.audio2.play()
        if(this.chart_data_2[0].data.x.length>10){
          let arrList2 = this.chart_data_2[0].data.x
          let num1 = arrList2.length -10
          let n = 0
          this.xdata2 = arrList2.slice(arrList2.length-9,arrList2.length)
          this.ydata2 = this.chart_data_2[0].data.y.slice(arrList2.length-9,arrList2.length)
          let inter1 = setInterval(()=>{
              n++;
              if(n<=num1){
                this.xdata2.pop()
                this.xdata2.unshift(this.chart_data_2[0].data.x[num1-n])
                this.ydata2.pop()
                this.ydata2.unshift(this.chart_data_2[0].data.y[num1-n])
                this.loadChart2()
              }
            },500)
          setTimeout(()=>{
            clearInterval(inter1)
          },(num1/2)*1000)
        }
        this.loadChart2();
      }, 15000);
      setTimeout( ()=> {
        $("#card3").addClass("animate__fadeOut");
      }, 27000);
      setTimeout( ()=> {
        $("#card3").addClass("none");
        $("#card4").removeClass("none");
        this.$refs.audio3.play()
        this.bussisDepartMove()
      }, 28000);
      setTimeout( ()=> {
        $("#card4").addClass("animate__fadeOut");
      }, 40000);
      setTimeout( ()=> {
        $("#card4").addClass("none");
        $("#card5").removeClass("none");
        this.$refs.endVideo.play()
      }, 41000);
      // 总共46秒
      if(this.device_id){
        setTimeout( ()=> {
          ApiServe.recordEnd({model_id:4,device_id:this.device_id,real:this.real})
        }, 51000);   //间隔
      }

    },
  },
};
</script>
<style scoped>
.card4_box{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.card4_box_title{
  color: #FFF06B;
  font-size: 16px;
}
.card4_box >p:first-of-type{
  width: 150px;
}
.card4_box >p:nth-of-type(2),.card4_box >p:nth-of-type(3){
  width: 80px;
  text-align: center;
}
.card4_content{
  height: 460px;
  overflow: hidden;
  position: relative;
}
.card4_box_move{
  position: absolute;
  width: 100%;
  bottom: 0;
}
.card4_content .card4_box{
  height: 40px;
}
.islu {
  color: #00b466;
}
.isred {
  color: #e54444;
}
</style>